import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../state/auth-state/auth.service';
import { ApplicationRoutes as appRouteMap } from 'src/app/configs/app-route.config';
import { GlobalObjectService } from '../../shared/services/global-object.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private globalObject: GlobalObjectService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.authService.isUserLoggedIn$.pipe(
      map((isLogedIn) => {
        if (isLogedIn) {
          return true;
        }
        return this.router.createUrlTree([appRouteMap.auth.login], {
          queryParams: {
            redirectUrl: state.url,
            returnUrl: this.globalObject.getLocation()?.pathname,
          },
        });
      })
    );
  }
}
